<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-displaynames/locale-data/en'
import '@formatjs/intl-displaynames/locale-data/zh-Hans'
import '@formatjs/intl-displaynames/locale-data/zh-Hant'

const props = defineProps({
  openDirection: {
    type: String as PropType<'UPWARDS' | 'DOWNWARDS'>,
    default: 'DOWNWARDS'
  }
})

const { locale, locales, setLocale } = useI18n()
const getLanguageDisplayName = (langCode: string) => {
  const languageNames = new Intl.DisplayNames([langCode], {
    type: 'language'
  })
  return languageNames.of(langCode)
}

const localeOptions = locales.value.map((l: string | LocaleObject) => {
  if (typeof l === 'string') {
    return {
      value: l,
      label: getLanguageDisplayName(l)
    }
  }
  return {
    value: l.code,
    label: getLanguageDisplayName(l.code)
  }
})

function handleLocaleChange(locale: string | string[]) {
  if (locale && typeof locale === 'string') setLocale(locale)
}
</script>

<template>
  <ClientOnly>
    <FormKit
      :open-direction="props.openDirection"
      type="tlSelectV2"
      :item-options="localeOptions"
      :model-value="locale"
      :multiple="false"
      @update:model-value="handleLocaleChange"
    />
  </ClientOnly>
</template>
